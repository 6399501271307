import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from '../assets/logo.png';
import { IoMenu } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

function Navbar() {
  let navItems = [
    { id: 1, name: "Home", link: "/" },
    { id: 2, name: "About Us", link: "/aboutus" },
    { id: 3, name: "Our Products", link: "/ourProducts" },
    { id: 3, name: "Contact Us", link: "/contactus" },
  ];

  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <nav style={{overflow: 'hidden'}} className="fixed top-0 left-0 z-50 flex items-center justify-between items-center px-10 py-2 bg-white w-full border-b-5 shadow-lg h-[10vh]">
      {/* Logo */}
      <NavLink
        to={"/"}
        className='company-logo'
      >
        <img src={logo} alt="company-logo" className="w-60 h-[8vh] object-contain" />
      </NavLink>

      {/* Desktop Navigation */}
      <ul className='hidden md:flex'>
        {navItems.map(item => (
          <NavLink
            to={item.link}
            style={({ isActive }) => ({
              color: isActive ? '#3CA5DC' : '',
            })}
            key={item.id}
            className='p-4 hover:text-[#3CA5DC] text-base font-bold font-open rounded-xl m-2 cursor-pointer duration-300'
          >
            {item.name}
          </NavLink>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block md:hidden cursor-pointer">
        {nav ?
          (
            <IoClose className="text-black w-7 h-7" />
          ) : (
            <IoMenu className="text-black w-7 h-7" />
          )}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? 'fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-b-2 shadow-lg bg-white ease-in-out duration-500 flex flex-col z-50'
            : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%] flex flex-col z-50'
        }
      >
        {/* Mobile Logo */}
        <div className="company-logo flex justify-center align-center my-4">
          <img src={logo} alt="company-logo" className="w-60 h-14 object-contain" />
        </div>

        {/* Mobile Navigation Items */}
        {navItems.map(item => (
          <NavLink
            to={item.link}
            style={({ isActive }) => ({
              color: isActive ? '#3CA5DC' : '',
            })}
            key={item.id}
            onClick={handleNav}
            className='p-4 text-base font-semibold font-open rounded-xl hover:text-[#3CA5DC] cursor-pointer z-50'
          >
            {item.name}
          </NavLink>
        ))}
      </ul>

      {/* <div className={`flex flex-col md:flex-row md:items-center md:pb-0 pb-5 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-100 ease-in ${nav ? 'top-20' : 'top-[-490px]'}`}>
        {navItems.map((link) => (
          <NavLink 
          to={link.link}
          style={({ isActive }) => ({
            color: isActive ? '#3CA5DC' : '#000000',
          })}
          className={`text-base sm:text-lg font-bold font-open duration-100 md:ml-8 md:my-0 my-4`}>
            {link.name}
          </NavLink>
        ))}
      </div> */}
    </nav>
  );
}

export default Navbar;
